import type { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { getPageWithWebsiteQueryHelpers } from 'data/page'
import { MainLayout } from 'components/layouts'
import { WebsiteSettings } from 'utils/types/sanity/generated-schema-types'
import { config } from 'config/config'

const { fetch, usePreviewSubscription } = getPageWithWebsiteQueryHelpers()

interface NotFoundProps {
  data: { websiteSettings: WebsiteSettings }
  preview: boolean
}

const NotFound: NextPage<NotFoundProps> = ({ data, preview }) => {
  const { data: settings } = usePreviewSubscription(data.websiteSettings, {}, preview)
  const { t } = useTranslation('error')

  return (
    <MainLayout title={t('not-found')} path='/' settings={settings} preview={preview}>
      <div className='bg-[#FDFAFF]'>
        <div className='flex items-center justify-center h-screen mx-4'>
          <div className='text-center'>
            <h1 className='pb-4 text-5xl font-semibold text-navy-900'>
              {t('error-404')}
            </h1>
            <h3 className='text-3xl text-navy-900'>{t('not-found')}</h3>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export const getStaticProps: GetStaticProps<NotFoundProps> = async ({
  preview = false,
  locale = config.defaultLocale,
}) => {
  const websiteSettings = await fetch({}, preview)

  return {
    props: {
      data: { websiteSettings },
      preview,
      ...(await serverSideTranslations(locale, ['error', 'footer'])),
    },
    revalidate: config.next.pageRevalidateDelay,
  }
}

export default NotFound
